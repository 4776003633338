<template>
  <div class="myOrder">
    <div class="go-back">
      <div @click="onClickLeft">
        <van-icon name="arrow-left" class="icon-left" />
        <span>我的订单</span>
      </div>
    </div>
    <div class="content" v-if="bill_approve_list">
      <div v-if='bill_approve_list.length == 0' class="">
        <van-empty description="暂无订单记录" />
      </div>
      <van-pull-refresh v-else v-model="isLoading" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :offset="50"
          :immediate-check="false"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoadData"
        >
          <div class="content-item" v-for="item in bill_approve_list" :key="item.bill_id">
            <div class="item-head">
              <span style="padding: 0px 2px;height: 12px;background-color: #1677ff;margin-right: 10px;"></span>
              <span class="title">{{ item.stage_type != 3 ? '账单分期': '快捷支付'}}</span>
              <div class="rests">
                <span v-if="item.bill_status == 0">审核中</span>
                <span v-if="item.bill_status == 1">办理成功</span>
                <span v-if="item.bill_status == 2">已完结</span>
                <span v-if="item.bill_status == 3">已关闭</span>
                <span v-if="item.bill_status == 4">已退款</span>
              </div>
            </div>
            <div class="stages-info">
              <div class="info-text">总金额：{{item.total_repayment_amount}}元</div>
              <div class="info-text">机构：{{item.full_name}}</div>
              <div v-if="item.contract_layer_name" class="info-text">商户全称：{{item.contract_layer_name}}</div>
              <div v-if="item.stage_type != 3" class="info-text">总期数：{{item.total_stage_count}}个月</div>
              <div class="info-text">办理时间：{{item.apply_stage_date}}</div>
              <div class="info-button">
                <div class="check" @click="onAllOrder(item)">全部账单</div>
                <div class="check refund" @click="onRefund(item)" v-if="item.is_apply_refund && item.bill_status == 1">退款</div>
              </div>
            </div>
            <div class="apply-process">
              <van-steps
                direction="vertical"
                :active="item.current_progress"
                active-color="#666666"
              >
                <van-step>
                  <div>提交申请 {{item.approve_list[0].approve_time}} </div>
                  <div v-if="item.approve_list[0].approve_status == 2">审核不通过</div>
                </van-step>
                <van-step>
                  <div>资料审核 {{item.approve_list[1].approve_time}}</div>
                  <div v-if="item.approve_list[1].approve_status == 2">审核不通过</div>
                </van-step>
                <van-step>
                  <div>商家确认 {{item.approve_list[2].approve_time}}</div>
                  <div v-if="item.approve_list[2].approve_status == 2">审核不通过</div>
                </van-step>
                <van-step>
                  <div>办理成功 {{item.approve_list[3].approve_time}}</div>
                  <div v-if="item.approve_list[3].approve_status == 2">审核不通过</div>
                </van-step>
              </van-steps>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <refund-payment ref="refundPayment" />
  </div>
</template>

<script>
import { billApproveList } from "@/api/bill.js";
import refundPayment from './components/refund-payment.vue';
export default {
  components: { refundPayment },
  name: "myOrder",

  data() {
    return {
      loading: false,
      isLoading: false,
      finished: false,
      searchForm: {
        page: 1,
        pageSize: 10,
      },
      bill_approve_list: null,
      active: 0,
    };
  },

  mounted() {
    this.initData();
  },

  methods: {
    async initData() {
      const { code, data } = await billApproveList(this.searchForm);
      if (code == 200) {
        let list = data.list.map(item => {
          var progress_list = []
          item.approve_list.map(val => {
            progress_list.push(val.approve_status)
          })
          item.progress_list = progress_list
          item.current_progress = progress_list.lastIndexOf(1)
          return item
        })
        if (this.searchForm.page == 1) {
          this.bill_approve_list = list;
        } else {
          this.bill_approve_list = (this.bill_approve_list || []).concat(list);
        }
      }
      this.loading = false;
      if (data.currentPage == data.totalPage || data.totalPage == 0) {
        this.finished = true;
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    onRefund(item){
      this.$refs.refundPayment.isShow(item)
    },
    onLoadData() {
      if (!this.finished) {
        this.loading = true;
        this.searchForm.page++;
        this.initData();
      }
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.searchForm.page = 1;
      this.initData();
    },
    onAllOrder(item) {
      localStorage.setItem('returnPath',"/myOrder")
      this.$router.push({
        path:"/paymentSchedule",
        query:{
          bill_id:item.bill_id
        }
      });
    },
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>

<style lang="scss" scoped>
.myOrder {
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  font-size: 30px;
  color: #333;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .go-back {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    height: 100px;
  }
  .content {
    flex: 1;
    overflow: auto;
    padding: 30px;
    background: #f5f5f5;
    .content-item {
      margin-bottom: 30px;
      padding: 30px 0px;
      background: #fff;
      box-shadow: 0px 0px 10px 0px #f5f5f5;
      border-radius: 14px;
      .item-head {
        display: flex;
        align-items: center;
        .title {
          flex: 1;
        }
        .rests {
          display: flex;
          align-items: center;
          border-radius: 50px 0px 0px 50px;
          background: #1677ff;
          padding: 10px 37px;
          color: #fff;
          font-size: 24px;
        }
      }
      .stages-info {
        margin: 30px 30px 0px 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #e6e6e6;
        .info-text {
          font-size: 30px;
          padding: 0px 10px 30px 10px;
        }
        .info-button {
          display: flex;
          .check {
            margin-right: 40px;
            padding: 15px 50px;
            color: #1677ff;
            border: 1px solid #1677ff;
            border-radius: 50px;
          }
          .refund{
            color: #333;
            border: 1px solid #333;
          }
        }
      }
    }
  }
}
</style>