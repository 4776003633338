<template>
  <div class="refund-payment">
    <van-dialog
      v-model="showPayment"
      class="payment-dialog"
      @confirm="confirm"
      @cancel="cancel"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="title">确认退款手续费</div>
      <div class="payment-content">
        退款手续费￥{{ paymentInfo.refund_amount }}元，支付后会完成退款流程
      </div>
      <van-radio-group v-model="payType" >
        <div class="pay-type">
          <div
            class="pay-item"
            v-if="bankCard"
            @click="getPayType('card')"
          >
            {{ bankCard.bank_name }}（{{ bankCard.bank_card_no }}）
            <div class="radiopay">
              <van-radio name="card" icon-size="16px"></van-radio>
            </div>
          </div>
          <div class="pay-item" v-if="isWeixin && openId != ''" @click="getPayType('WeChat')">
            微信支付
            <div class="radiopay">
              <van-radio name="WeChat" icon-size="16px"></van-radio>
            </div>
          </div>
          <div class="pay-item" @click="getPayType('aliPay')">
            支付宝支付
            <div class="radiopay">
              <van-radio name="aliPay" icon-size="16px"></van-radio>
            </div>
          </div>
        </div>
      </van-radio-group>
      <div class="dialog-button">
        <div class="button-cancel" @click="cancel">取消</div>
        <div class="button-confim" @click="confirm" v-if="!flag">确定支付</div>
        <div class="button-confim" v-else style="background: #708eb8">
          支付中...
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { advanceRepayment, prePaymentByWechat } from "@/api/bill.js";
import { Toast } from "vant";
import { getCardList } from "@/api/bank.js";
import { isWeixin } from "@/utils/index.js";
import {prePaymentByAliPay} from "../../../api/bill";

export default {
  data() {
    return {
      showPayment: false,
      paymentInfo: {},
      flag: false,
      payType: "card",
      isWeixin: isWeixin(),
      openId: "",
      bankCard: null,
    };
  },

  mounted() {
    this.openId = localStorage.getItem("openId");
  },

  methods: {
    async isShow(item) {
      this.showPayment = true;
      this.paymentInfo = item;
      this.getCard();
    },
    async getCard() {
      const { code, data } = await getCardList(this.serchForm);
      if (code == 200) {
        if (data.list.length == 0) {
          if(this.isWeixin){
            this.payType = "WeChat"
          } else {
            this.payType = "aliPay"
          }
        } else {
          data.list.map((item) => {
            if (item.is_default == 1) {
              this.bankCard = {
                bank_name: item.bank_name,
                bank_card_no: item.bank_card_no.substring(
                  item.bank_card_no.length - 4,
                  item.bank_card_no.length
                ),
              };
            }
          });
        }
      }
    },
    getPayType(type) {
      this.payType = type;
    },
    async confirm() {
      if (this.flag) return;
      this.flag = true;
      if (this.payType == "card") {
        if(!this.bankCard){
          Toast("请先绑定银行卡");
          this.flag = false;
        } else {
          this.getCardPay();
        }
      } else if (this.payType == "WeChat") {
        this.getWeChatPay();
      } else if (this.payType == "aliPay") {
        this.getAliPay()
      }
    },
      async getAliPay() {
          const that = this;
          const parmes = {
              billId: this.paymentInfo.bill_id,
              advanceType: 4,
          };
          const { code, data } = await prePaymentByAliPay(parmes);
          this.flag = false;
          if (code == 200) {
              that.aliPay(data.prepay);
          }
      },
    // 支付宝支付
    aliPay(prepay){
        if (prepay) {
            this.$router.push({
                path: "/AlipayPayment",
                query: {
                    alipay_url: prepay,
                },
            });
        } else {
            // TODO 错误提示
        }
    },
    // 微信支付
    async getWeChatPay() {
      const that = this;
      const parmes = {
        billId: this.paymentInfo.bill_id,
        advanceType: 4,
        openId: this.openId,
      };
      const { code, data } = await prePaymentByWechat(parmes);
      this.flag = false;
      if (code == 200) {
        that.wxPay(data.prepay);
      }
    },
    wxPay(prepay) {
      var vm = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            vm.onBridgeReady(prepay),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", vm.onBridgeReady(prepay));
          document.attachEvent(
            "onWeixinJSBridgeReady",
            vm.onBridgeReady(prepay)
          );
        }
      } else {
        vm.onBridgeReady(prepay);
      }
    },
    onBridgeReady(prepay) {
      const that = this;
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: prepay.appId,
          timeStamp: prepay.timeStamp,
          nonceStr: prepay.nonceStr,
          package: prepay.package,
          signType: prepay.signType,
          paySign: prepay.paySign,
        },
        function (res) {
          // console.log('weixinzhifu',res)
          that.flag = false;
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            Toast.clear();
            Toast.success("支付成功");
            this.showPayment = false;
            that.$parent.initData();
            that.flag = false;
          } else {
            Toast("支付失败");
            that.flag = false;
          }
        }
      );
    },
    async getCardPay() {
      let that = this;
      const parmes = {
        billId: this.paymentInfo.bill_id,
        advanceType: 4,
      };
      const { code } = await advanceRepayment(parmes);
      that.flag = false;
      if (code == 200) {
        Toast("退款成功");
        that.showPayment = false;
        that.$parent.initData();
        that.flag = false;
      } else {
        that.flag = false;
      }
    },
    cancel() {
      this.showPayment = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.refund-payment {
  color: #333;
  font-size: 28px;
  .title {
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  .payment-content {
    font-size: 28px;
    border-top: 1px solid #d9d9d9;
    padding: 20px 40px;
    .content-item {
      display: flex;
      margin: 20px 0px;
      .item-label {
        flex: 1;
      }
      .item-value {
        text-align: right;
      }
    }
    .amount {
      color: #333;
      font-weight: 600;
    }
  }
  .pay-type {
    margin: 20px 40px;
    font-size: 28px;
    .pay-item {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 20px;
      margin: 20px 0px;
      border: 1px solid #1677ff;
      box-shadow: 0px 0px 10px 0px #fff;
      border-radius: 14px;
      .radiopay {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }
    }
  }
  .dialog-button {
    display: flex;
    align-items: center;
    padding: 30px 40px 40px;
    .button-cancel,
    .button-confim {
      border: 1px solid #1677ff;
      border-radius: 35px;
      flex: 1;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1677ff;
    }
    .button-confim {
      background: #1677ff;
      color: #fff;
    }
    .button-cancel + .button-confim {
      margin-left: 30px;
    }
  }
}
</style>